const landingMenuData =
[
    // {
    //   title: '레몬머니레터',
    //   id: 'LETTER',
    //   menuIcon: '📮',
    //   routerPath: 'LANDING_LETTER_LIST',
    // },
    {
      title: '블로그 관리',
      id: 'BLOG',
      menuIcon: '📢',
      // routerPath: 'LANDING_NOTICE_LIST',
      menuList: [ 
        {
          title: '이벤트 관리',
          id: 'NOTICE',
          routerPath: 'LANDING_BLOG_NOTICE_LIST',
        },
        {
          title: '포스트 관리',
          id: 'POST',
          routerPath: 'LANDING_BLOG_POST_LIST',
        },
        {
          title: '공지사항 관리',
          id: 'NEWS',
          routerPath: 'LANDING_BLOG_NEWS_LIST',
        },
      ]
    },
    {
      title: '채용공고 관리',
      id: 'RECRUIT',
      menuIcon: '🤝',
      routerPath: 'LANDING_RECRUIT_LIST',
    },
    {
      title: '출시알림 이벤트 통계',
      id: 'STATISTIC',
      menuIcon: '🎉',
      routerPath: 'LANDING_STATISTIC',
      // menuList: [ 
      //   {
      //     title: '이벤트통계',
      //     id: 'STATISTIC',
      //   },
      //   {
      //     title: '이벤트관리',
      //     id: 'MANAGE',
      //   },
      // ]
    },
    // {
    //   title: '소개관리',
    //   id: 'PRODUCT',
    //   menuIcon: '💁‍♀️',
    // },
    // {
    //   title: '구성원관리',
    //   id: 'MEMBER',
    //   menuIcon: '👥',
    // },
    // {
    //   title: '협력사관리',
    //   id: 'PARTNER',
    //   menuIcon: '🤝',
    // },
    // {
    //   title: '보도자료관리',
    //   id: 'PRESS',
    //   menuIcon: '📰',
    // },
    {
      title: '캐시 무효화',
      id: 'CACHE_INVALIDATION',
      menuIcon: '🗑️',
      routerPath: 'LANDING_CACHE_INVALIDATION',
    },
]

export { landingMenuData }