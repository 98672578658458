const serviceMenuData =
[
  {
    title: '퍼핀 회원관리',
    id: 'USER',
    menuIcon: '👤',
    menuList: [ 
      {
        title: '회원정보',
        id: 'SEARCH',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_USER_SEARCH_LIST',
      },
      {
        title: '회원정보+',
        id: 'PLUS',
        requiresMinRole: 'ADMIN',
        routerPath: 'SERVICE_USER_PLUS_LIST',
      },
      {
        title: '탈퇴 회원관리',
        id: 'LEAVE',
        requiresMinRole: 'ADMIN',
        routerPath: 'SERVICE_USER_LEAVE_LIST',
      },
    ]
  },
  {
    title: '멤버십 관리',
    id: 'MEMBERSHIP',
    menuIcon: '🎖',
    menuList: [ 
      {
        title: '구매내역 조회',
        id: 'PAYMENTS',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_MEMBERSHIP_PAYMENTS_LIST',
      },
    ]
  },
  {
    title: '약관관리',
    id: 'TERM',
    menuIcon: '🖌',
    routerPath: 'SERVICE_TERM_LIST',
  },
  // {
  //   title: '문의관리',
  //   id: 'INQUIRY',
  //   menuIcon: '💁‍♀️',
  //   routerPath: 'SERVICE_INQUIRY_LIST',
  // },
  {
    title: 'FAQ관리',
    id: 'FAQ',
    menuIcon: '💬',
    // routerPath: 'SERVICE_FAQ_LIST',
    menuList: [ 
      {
        title: 'FAQ관리',
        id: 'FAQLIST',
        routerPath: 'SERVICE_FAQ_LIST',
      },
      {
        title: 'TOP 10관리',
        id: 'TOPTEN',
        routerPath: 'SERVICE_FAQ_TOPTEN',
      },
      {
        title: '카테고리 관리',
        id: 'CATEGORY',
        routerPath: 'SERVICE_FAQ_CATEGORY',
      },
    ]
  },
  {
    title: '프로모션 코드 관리',
    id: 'PROMOTION',
    menuIcon: '💎',
    routerPath: 'SERVICE_PROMOTION_LIST',
  },
  {
    title: '리딤코드 관리',
    id: 'REDEEM',
    menuIcon: '🎟️',
    routerPath: 'SERVICE_REDEEM_LIST',
  },
]
export { serviceMenuData }