const apiPath = {
  // 로그인
  PUBLIC_KEY: '/api/auth/v1/public-key',
  LOGIN: '/api/auth/v1/login',
  MAF_LOGIN: '/api/auth/v1/mfa',
  LOGOUT: '/api/auth/v1/logout',
  // DECRYPT: '/api/auth/v1/test_decrypt',

  // ++ Service
  // 회원관리
  USER_LIST: '/api/user/v1/users', // 목록
  // USER_REGISTER: '/api/user/v1/user', // 생성, 수정
  USER_DETAIL: '/api/user/v1/user/%s', // 상세
  // USER_DELETE: '/api/user/v1/user', // 삭제
  // USER_LOCK: '/api/user/v1/user/%s/locked', // 잠금
  // USER_UNLOCK: '/api/user/v1/user/%s/unlocked', // 잠금해제
  // 멤버십관리
  MEMBERSHIP_PAYMENTS_LIST: '/api/membership/v1/user_memberships', //모든 구매 내역 페이징
  MEMBERSHIP_PAYMENTS_USER_ID: '/api/membership/v1/user_memberships/user_id/%s', //user_id로 검색 [user_id]
  MEMBERSHIP_PAYMENTS_MEMBERSHIP_ID: '/api/membership/v1/user_memberships/membership_id/%s', //구매한 멤버쉽 ID로 검색[membershipId]
  MEMBERSHIP_PAYMENTS_PURCHASE_METHOD_TYPE: '/api/membership/v1/user_memberships/payment_method_type/%s', //결제타입으로 검색[paymentMethodType]
  MEMBERSHIP_PAYMENTS_CANCEL: '/api/membership/v1/user_membership/cancel', //구매 취소(환불은 수동)
  MEMBERSHIP_JOIN: '/api/membership/v1/user_membership', //멤버십 추가
  MEMBERSHIP_ID_LIST: '/papi/membership/v1/memberships', //멤버십 종류 목록
  
  // FAQ관리
  FAQ_LIST: '/api/faq/v1/faqs', // 목록
  FAQ_REGISTER: '/api/faq/v1/faq', // FAQ 생성
  FAQ_DETAIL: '/api/faq/v1/faq/%s', // FAQ 상세, 수정
  FAQ_DELETE: '/api/faq/v1/faq/%s', // FAQ 삭제

  FAQ_TOP_LIST: '/api/faq/v1/faq/top', // top10 목록
  FAQ_TOP_REGISTER: '/api/faq/v1/faq/top/%s/%s', // top10 생성, 수정 [faq_id, position]

  FAQ_CATEGORY: '/api/faq/v1/categories', // 카테고리 목록
  FAQ_CATEGORY_REGISTER: '/api/faq/v1/faqcategory', // 카테고리 생성
  FAQ_CATEGORY_MODIFY: '/api/faq/v1/faqcategory/%s', // 카테고리 수정 [id]
  FAQ_CATEGORY_DELETE: '/api/faq/v1/faqcategory/%s', // 카테고리 삭제 [id]
  FAQ_CATEGORY_POSITION: '/api/faq/v1/faqcategory/%s/position', // 카테고리 순서 변경 [id]

  // 프로모션 코드 관리
  COUPON_LIST: '/api/coupon/register/v1/coupons', // 목록
  COUPON_REGISTER: '/api/coupon/register/v1/coupon', // 생성, 수정
  COUPON_DETAIL: '/api/coupon/register/v1/coupon/%s', // 상세

  // 리딤코드 관리
  REDEEM_LIST: '/api/redeem/v1/redeems', // 목록
  REDEEM_REGISTER: '/api/redeem/v1/redeems_with_membership_id/%s/%s/%s/%s', // 등록 [membershipId, categoryType, mapId, count]

  // 포인트벌기
  MORE_POINT_PARENTS_LIST: '/api/life/point/v1/', // 목록
  MORE_POINT_PARENTS_REGISTER: '/api/life/point/v1/point', // 등록
  MORE_POINT_PARENTS_MODIFY: '/api/life/point/v1/point/%s', // 수정 [id]
  MORE_POINT_PARENTS_POSITION: '/api/life/point/v1/point/%s/position', // 순서 [id]
  MORE_POINT_CHILD_LIST: '/api/life/allowance/v1/', // 목록
  MORE_POINT_CHILD_REGISTER: '/api/life/allowance/v1/allowance', // 등록
  MORE_POINT_CHILD_MODIFY: '/api/life/allowance/v1/allowance/%s', // 수정 [id]
  MORE_POINT_CHILD_POSITION: '/api/life/allowance/v1/allowance/%s/position', // 순서 [id]

  // ** App
  // dashboard
  SCHEDULE_LIST: '/api/memo/v1/memo',
  // 광고관리
  ADVERTISEMENT_LIST: '/api/advertisement/v1/advertisements', // 목록
  ADVERTISEMENT_REGISTER: '/api/advertisement/v1/advertisement', // 등록
  ADVERTISEMENT_MODIFY: '/api/advertisement/v1/advertisement/%s', // 수정 [id]
  // 베너관리
  // 베너게시관리
  BANNER_POSTING_LIST: '/api/banner/v1/banners', // 목록
  BANNER_POSTING_REGISTER: '/api/banner/v1/banner/%s/sequence/%s', // 순서변경 [bannerContentsId, sequence]
  BANNER_POSTING_POSITION: '/api/banner/v1/banners/positions', // 저장
  BANNER_POSTING_DELETE: '/api/banner/v1/banner/%s', // 삭제 [sequence]
  // 베너소스관리
  BANNER_SOURCE_LIST: '/api/banner/v1/banner_contents', // 목록
  BANNER_SOURCE_SEARCH: '/api/banner/v1/banner/search/%s', // 베너소스 검색 [keyword]
  BANNER_SOURCE_REGISTER: '/api/banner/v1/banner_contents', // 등록
  BANNER_SOURCE_MODIFY: '/api/banner/v1/banner_content/%s', // 수정 [id]
  BANNER_SOURCE_DETAIL: '/api/banner/v1/banner_contents/%s', // 상세 [id]
  // 알림관리
  ALARM_LIST: '/api/notification/v1/schedules', // 목록
  ALARM_KEYWORD_SEARCH: '/api/notification/v1/schedule/search/%s', // 키워드 검색
  ALARM_REGISTER: '/api/notification/v1/schedule', // 등록
  ALARM_MODIFY: '/api/notification/v1/schedule/%s', // 수정 [id]
  ALARM_DETAIL: '/api/notification/v1/schedule/%s', // 상세 [id][keyword]

  // ** Store
  // 가맹점 관리
  SOTRE_LIST : '/api/firfinpay/affiliate/v1/affiliates', // 목록
  SOTRE_REGISTER : '/api/firfinpay/affiliate/v1/affiliate/%s/user', //생성 [affiliateId]
  SOTRE_DETAIL : '/api/firfinpay/affiliate/v1/affiliate/%s/users', //상세 [affiliateId]

  // ** landing
  // 이벤트 통계 - 출시알람
  SURVEY_SUMMARY: "/api/survey/v1/total",
  SURVEY_DAILY: "/api/survey/v1/daily",
  SURVEY_INFO: "/api/survey/v1/surveys",
  // 레몬레터
  LETTER: "/api/lemonletter/v1/lemonletter",
  LETTER_LIST: "/api/lemonletter/v1/lemonletters",
  // 이벤트
  NOTICE_LIST: '/api/notice/v1/notices',
  NOTICE_LIST_SEARCH: '/api/notice/v1/notice/search/%s', // 목록 검색 [keyword]
  NOTICE_DETAIL:'/api/notice/v1/notice/%s',
  NOTICE_REGISTER:'/api/notice/v1/notice',
  NOTICE_MODIFY:'/api/notice/v1/notice/%s',
  NOTICE_DELETE:'/api/notice/v1/notice/%s', 
  // 포스트
  POST_LIST: '/api/post/v1/posts',
  POST_LIST_SEARCH: '/api/post/v1/post/search/%s', // 목록 검색 [keyword]
  POST_DETAIL:'/api/post/v1/post/%s',
  POST_REGISTER:'/api/post/v1/post',
  POST_MODIFY:'/api/post/v1/post/%s',
  POST_DELETE:'/api/post/v1/post/%s',
  POST_HOT_LIST: '/api/post/v1/post/hots',
  // 공지사항
  NEWS_LIST: '/api/news/v1/news',
  NEWS_DETAIL:'/api/news/v1/news/%s',
  NEWS_REGISTER:'/api/news/v1/news',
  NEWS_MODIFY:'/api/news/v1/news/%s',
  NEWS_DELETE:'/api/news/v1/news/%s',
  // 채용관리
  RECRUIT_LIST: '/api/recruit/v1/recruits',
  RECRUIT_DETAIL:'/api/recruit/v1/recruit/%s',
  RECRUIT_REGISTER:'/api/recruit/v1/recruit',
  RECRUIT_MODIFY:'/api/recruit/v1/recruit/%s',
  RECRUIT_DELETE:'/api/recruit/v1/recruit/%s',

  INVALIDATE_CACHE: '/api/cdn/v1/cdn/invalidate',

  // ** setting
  // 직원관리
  ADMIN_LIST: '/api/admin/v1/admins', // 목록
  ADMIN_REGISTER: '/api/admin/v1/admin', // 생성, 수정
  ADMIN_DETAIL: '/api/admin/v1/admin', // 상세
  ADMIN_DELETE: '/api/admin/v1/admin', // 삭제
  ADMIN_LOCK: '/api/admin/v1/admin/%s/locked', // 잠금
  ADMIN_UNLOCK: '/api/admin/v1/admin/%s/unlocked', // 잠금해제
  // 시스템 로그
  SYSTEM_LIST: '/papi/logs/v1/list',
  // DB 테이블 조회
  DB_SEARCH_LIST : '/api/info/v1/query',
  // 내정보
  ME: '/api/admin/v1/me',
  CHANGE_PASSWORD: '/api/admin/v1/change_password', // 비밀번호 변경
  MFA_SETTING: '/api/admin/v1/mfa', // mfa 설정

  // 첨부파일
  PRESIGNED_URL: '/api/upload/v1/presigned-url',
  FILE_UPLOAD: '/api/upload/v1/upload',
};

export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};