// (더미)상태
export const dummyStatusDataList = [
  { id:'TODO', text:'할일' },
  { id:'DOING', text:'진행중' },
  { id:'DONE', text:'완료' },
  { id:'HOLD', text:'보류' },
]

// (더미)상태2
export const dummy2StatusDataList = [
  { id:'High', text:'높음' },
  { id:'Normal', text:'보통' },
  { id:'Light', text:'낮음' },
  { id:'None', text:'없음' },
]
export const dummy_status2_icon = [
  { id:'High', text:'arrow_top' },
  { id:'Normal', text:'drag' },
  { id:'Light', text:'arrow_bottom' },
  { id:'None', text:'cross' },
]

// 공통
export const common_boolean = [
  { id:true, text:'Y' },
  { id:false, text:'N' },
]
export const common_boolean_ox = [
  { id:true, text:'O' },
  { id:false, text:'X' },
]
export const common_cs_status = [
  { id:'REGERVVATION', text:'접수' },
  { id:'ING', text:'응대중' },
  { id:'COMPLETE', text:'완료' },
]

// 유저 타겟
export const user_target = [
  { id:'ALL', text:'자녀앱/부모앱' },
  { id:'CHILD', text:'자녀앱' },
  { id:'PARENTS', text:'부모앱' },
]

// 서비스 > 회원관리
export const user_role = [
  { id:'PARENTS', text:'부모' },
  { id:'CHILD', text:'자녀' },
]
export const user_role_detail = [
  { id:'CHILD_UNDER_FOURTEEN', text:'14세미만 자녀회원' },
  { id:'CHILD', text:'14세이상 자녀회원' },
  { id:'PARENTS', text:'부모회원' },
]
export const user_family_leader = [
  { id:true, text:'(대표부모)' },
  { id:false, text:'' },
]
export const user_status_name = [
  // { id:'ready', text:'대기중' },
  // { id:'active', text:'활성화' },
  // { id:'quit', text:'탈퇴' },
  // { id:'inacitve', text:'휴면' },
  // { id:'restricted', text:'제재' },
  { id:'EXPIRED', text:'만료' },
  { id:'PENDING', text:'대기' },
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
]

// 서비스 > 카드 관리
export const card_type = [
  { id:'NEW', text:'신규발급' },
  { id:'REAPPLY', text:'재발급' },
]
export const card_status = [
  { id:'REGISTERED', text:'등록' },
  { id:'UNREGISTERED', text:'해지' },
  { id:'SUSPENDED', text:'중지' },
  { id:'EXPIRED', text:'만료' },
]
export const card_apply_status = [
  { id:'PENDING', text:'임시저장' },
  { id:'APPLY', text:'신청서확정' },
  { id:'REGISTERED', text:'코나제출' },
  { id:'DELIVERY', text:'배송중' },
  { id:'ARRIVE', text:'배송완료' },
  { id:'CANCELED', text:'배송취소' },
]

// 서비스 > 멤버십관리
export const membership_type = [
  { id:'PLUS_USER', text:'플러스' },
  { id:'BANK_ASSOCIATION', text:'은행연합회' },
  { id:'ECONOMY_NEWS', text:'경제뉴스' },
]

export const membership_purchase_method_type = [
  { id:'FREE', text:'무료' },
  { id:'REDEEM', text:'리딤' },
  { id:'APPLE', text:'apple' },
  { id:'GOOGLE', text:'google' },
  { id:'IMWEB', text:'아임웹' },
]

export const membership_payment_status = [
  { id:'PAYMENT', text:'완료' },
  { id:'CANCEL', text:'취소' },
]

// 서비스 > 약관 관리
export const term_status = [
  { id: 'REGIVATION', text:'배포예약' },
  { id: 'TEMP', text:'임시저장' },
  { id: 'DEPLOIED', text:'배포완료' },
]
export const term_status_icon = [
  { id: 'REGIVATION', text:'🕓' },
  { id: 'TEMP', text:'🛠' },
  { id: 'DEPLOIED', text:'✅' },
]

// 서비스 > 프로모션 코드
export const promotion_status = [
  { id: 'ING', text:'진행중' },
  { id: 'PLAN', text:'예약' },
  { id: 'END', text:'만료' },
  { id: 'HOLD', text:'중단' },
]
export const promotion_price = [
  { id: '0', text:'0원' },
  { id: '1000', text:'1,000원' },
  { id: '2000', text:'2,000원' },
  { id: '3000', text:'3,000원' },
  { id: '4000', text:'4,000원' },
  { id: '5000', text:'5,000원' },
  { id: '10000', text:'10,000원' },
  { id: '15000', text:'15,000원' },
  { id: '20000', text:'20,000원' },
]

// 리딤코드 관리 > 카테고리 타입
export const redeem_category_type = [
  { id: 1, text: '1' }
];

// 앱관리 > 광고관리
export const advertisement_status = [
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
]
export const advertisement_inventory = [
  { id:'POPUP', text:'팝업' },
  { id:'SECTION', text:'섹션' },
  { id:'INTRO', text:'스플래시' },
  { id:'CLOSE', text:'앱종료' },
]
export const advertisement_section_background_color = [
  { id:'#FFFF80', text:'yellow' },
  { id:'#C0FF80', text:'green' },
  { id:'#FFCCFD', text:'pink' },
  { id:'#FFAEAE', text:'red' },
  { id:'#FFC080', text:'ornage' },
  { id:'#B3D8FF', text:'blue' },
  { id:'#FFFFFF', text:'white' },
  { id:'#DDDBEB', text:'gray' },
  { id:'', text:'직접입력' },
]


// 앱관리 > 알림
export const alarm_test = [
  { id:true, text:'테스트' },
  { id:false, text:'N' },
]
export const alarm_send_type = [
  { id:'ALL', text:'전체발송' },
  { id:'EXCEL', text:'퍼핀유저 ID로 특정(엑셀업로드)' },
  { id:"TEST", text:'테스트 발송(직원들만)' },
]
export const alarm_ad = [
  { id:true, text:'광고' },
  { id:false, text:'N' },
]
export const alarm_ad_register = [
  { id:true, text:'광고' },
  { id:false, text:'광고아님' },
]
export const alarm_platform = [
  { id:'ALL', text:'ALL' },
  { id:'IOS', text:'iOS' },
  { id:'ANDROID', text:'Android' },
]
export const alarm_chanel = [
  { id:'GUIDE', text:'퍼핀가이드', imgChanel:'https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/profile/guide.png' },
  { id:'FIRFINCARD', text:'퍼핀카드', imgChanel:'https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/profile/card.png' },
  { id:'FIRFINCITY', text:'퍼핀월드', imgChanel:'https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/profile/world.png' },
  { id:'FIRFININVEST', text:'퍼핀투자', imgChanel:'https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/profile/invest.png' },
  { id:'NOTICE', text:'퍼핀이벤트', imgChanel:'https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/profile/event.png' },
  { id:'FIRFINPLUS', text:'퍼핀플러스', imgChanel:'https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/profile/plus.png' },
]
export const alarm_status = [
  { id:'REGISTERED', text:'발송예약' },
  { id:'UNREGISTERED', text:'발송취소' },
  { id:'RUNNING', text:'발송중' },
  { id:'DONE', text:'발송완료' },
  { id:'FAILED', text:'발송실패' },
]

// 포인트 받기 관리 상태
export const more_point_status = [
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
]

// 랜딩 페이지 관리 > 블로그 관리
export const blog_status = [
  { id:'NORMAL', text:'활성화' },
  { id:'HIDDEN', text:'비활성화' },
]

// 랜딩 페이지 관리 > 블로그 관리 > 포스트 관리
export const post_type = [
  { id:'NORMAL', text:'일반' },
  { id:'HOT', text:'추천' },
]

// 직원관리 > 회원 권한
export const member_role = [
  { id:'ADMIN', text:'관리자' },
  { id:'USER', text:'일반' },
]

// 직원관리 > 회원 로그인 상태
export const member_login_status = [
  { id:'OK', text:'정상 로그인' },
  { id:'LOCKED', text:'로그인 잠금' },
  { id:'NEED_MFA', text:'MFA 확인 필요' },
]

// 직원관리 > 회원 로그인 상태 아이콘
export const member_login_status_icon = [
  { id:'OK', text:'login_ok' },
  { id:'LOCKED', text:'login_lock' },
  { id:'NEED_MFA', text:'login_setting' },
]

// 스케줄 타입 // APP, BANK, CONTENT, PAGE
export const scheduleTypeDataList = [
  { id:'GENERAL', text:'공통' },
  { id:'CONTENT', text:'컨텐츠' },
  { id:'APP', text:'App' },
  { id:'PAGE', text:'Landing Page' },
  { id:'BANK', text:'은행' },
]


export const selectDataList = {
  // ** (더미)상태
  dummy_status : dummyStatusDataList,
  dummy_status2 : dummy2StatusDataList,
  dummy_status2_icon : dummy_status2_icon,

  common_boolean : common_boolean,
  common_boolean_ox : common_boolean_ox,
  common_cs_status: common_cs_status,

  user_target : user_target,

  user_role : user_role,
  user_role_detail : user_role_detail,
  user_family_leader : user_family_leader,
  user_status_name : user_status_name,

  card_type : card_type,
  card_status : card_status,
  card_apply_status : card_apply_status,

  membership_type : membership_type,
  membership_purchase_method_type : membership_purchase_method_type,
  membership_payment_status : membership_payment_status,

  term_status : term_status,
  term_status_icon  : term_status_icon,

  promotion_status : promotion_status,
  promotion_price : promotion_price,

  redeem_category_type : redeem_category_type,

  advertisement_status : advertisement_status,
  advertisement_inventory : advertisement_inventory,
  advertisement_section_background_color: advertisement_section_background_color,

  alarm_test : alarm_test,
  alarm_send_type : alarm_send_type,
  alarm_ad : alarm_ad,
  alarm_ad_register : alarm_ad_register,
  alarm_platform : alarm_platform,
  alarm_chanel : alarm_chanel,
  alarm_status : alarm_status,

  more_point_status: more_point_status,

  blog_status : blog_status,

  post_type : post_type,

  member_role : member_role, // 회원 권한
  member_login_status : member_login_status, // 회원 로그인 상태
  member_login_status_icon : member_login_status_icon, // 회원 로그인 상태

  schedule_type: scheduleTypeDataList, // 스케줄 타입
}