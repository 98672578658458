const state = {
  isPasswordChangeError: false,
}

const getters = {
	getIsPasswordChangeError: (state) => state.isPasswordChangeError,
}

const actions = {
  fetchPasswordChangeErrorStart({ commit }, params) {
    if(state.isPasswordChangeError) return
    // console.log('fetchPasswordChangeErrorStart')
    commit('START_PASSWORD_CHANGE_MUTATION', params);
  },
  fetchPasswordChangeErrorEnd({ commit }, params) {
    if(!state.isPasswordChangeError) return
    // console.log('fetchPasswordChangeErrorEnd')
    commit('END_PASSWORD_CHANGE_MUTATION', params);
  },
}

const mutations = {
  START_PASSWORD_CHANGE_MUTATION(state, payload) {
    state.isPasswordChangeError = true;
  },
  END_PASSWORD_CHANGE_MUTATION(state, payload) {
    state.isPasswordChangeError = false;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}