const routerComponent = {
  LOGIN : () => import('@/views/login/view/Login.vue'),
  MY: () => import('@/views/my/view/My.vue'),

  // 대시보드
  DASHBOARD_DASHBOARD: () => import('@/views/dashboard/dashboard/view/Dashboard.vue'),
  // --- service ---
  // 회원관리
  SERVICE_USER_SEARCH_LIST: () => import('@/views/service/user/view/search/UserSearchList.vue'),
  SERVICE_USER_SEARCH_DETAIL: () => import('@/views/service/user/view/search/UserDetail.vue'),
  // 회원관리+
  SERVICE_USER_PLUS_LIST: () => import('@/views/service/user/view/plus/UserPlusList.vue'),
  SERVICE_USER_PLUS_DETAIL: () => import('@/views/service/user/view/plus/UserDetail.vue'),
  // 탈퇴회원관리
  SERVICE_USER_LEAVE_LIST: () => import('@/views/service/user/view/leave/UserLeaveList.vue'),
  SERVICE_USER_LEAVE_DETAIL: () => import('@/views/service/user/view/leave/UserDetail.vue'),
  // 문의관리
  SERVICE_INQUIRY_LIST: () => import('@/views/service/inquiry/view/InquiryList.vue'),
  SERVICE_INQUIRY_DETAIL: () => import('@/views/service/inquiry/view/InquiryDetail.vue'),
  // 멤버십 구매내역 조회
  SERVICE_MEMBERSHIP_PAYMENTS_LIST: () => import('@/views/service/membership/payments/view/MembershipPaymentsList.vue'),
  // 약관관리
  SERVICE_TERM_LIST: () => import('@/views/service/term/view/TermList.vue'),
  SERVICE_TERM_DETAIL: () => import('@/views/service/term/view/TermDetail.vue'),
  SERVICE_TERM_REGISTER: () => import('@/views/service/term/view/TermRegister.vue'),
  SERVICE_TERM_MODIFY: () => import('@/views/service/term/view/TermRegister.vue'),
  // FAQ관리
  SERVICE_FAQ_LIST: () => import('@/views/service/faq/view/FaqList.vue'),
  SERVICE_FAQ_REGISTER: () => import('@/views/service/faq/view/FaqRegister.vue'),
  SERVICE_FAQ_MODIFY: () => import('@/views/service/faq/view/FaqRegister.vue'),
  SERVICE_FAQ_TOPTEN: () => import('@/views/service/faq/view/FaqTopTen.vue'),
  SERVICE_FAQ_CATEGORY: () => import('@/views/service/faq/view/FaqCategory.vue'),
  // 프로모션 코드 관리
  SERVICE_PROMOTION_LIST: () => import('@/views/service/promotion/view/PromotionList.vue'),
  SERVICE_PROMOTION_REGISTER: () => import('@/views/service/promotion/view/PromotionRegister.vue'),
  SERVICE_PROMOTION_MODIFY: () => import('@/views/service/promotion/view/PromotionRegister.vue'),

  SERVICE_REDEEM_LIST: () => import('@/views/service/redeem/view/RedeemList.vue'),
  SERVICE_REDEEM_REGISTER: () => import('@/views/service/redeem/view/RedeemRegister.vue'),

  // --- app ---
  // 점검관리
  APP_INSPECTION: () => import('@/views/app/inspection/view/Inspection.vue'),
  // 광고관리
  APP_ADVERTISEMENT_LIST: () => import('@/views/app/advertisement/view/AdvertisementList.vue'),
  // 업데이트관리
  APP_UPDATE_LIST: () => import('@/views/app/update/view/UpdateList.vue'),
  APP_UPDATE_DETAIL: () => import('@/views/app/update/view/UpdateDetail.vue'),
  APP_UPDATE_REGISTER: () => import('@/views/app/update/view/UpdateRegister.vue'),
  // APP_UPDATE_MODIFY: () => import('@/views/app/update/view/UpdateModify.vue'),
  // 알림
  APP_ALARM_LIST: () => import('@/views/app/alarm/view/AlarmList.vue'),
  APP_ALARM_DETAIL: () => import('@/views/app/alarm/view/AlarmDetail.vue'),
  APP_ALARM_REGISTER: () => import('@/views/app/alarm/view/AlarmRegister.vue'),
  APP_ALARM_MODIFY: () => import('@/views/app/alarm/view/AlarmRegister.vue'),
  // 베너관리
  APP_BANNER_POSTING: () => import('@/views/app/banner/view/BannerPosting.vue'),
  APP_BANNER_SOURCE_LIST: () => import('@/views/app/banner/view/BannerSourceList.vue'),
  APP_BANNER_SOURCE_REGISTER: () => import('@/views/app/banner/view/BannerSourceRegister.vue'),
  APP_BANNER_SOURCE_MODIFY: () => import('@/views/app/banner/view/BannerSourceRegister.vue'),
  // 포인트벌기관리
  APP_MOREPOINT_LIST: () => import('@/views/app/morePoint/view/MorePointList.vue'),

  // --- landing ---
  // 출시알림 이벤트 통계
  LANDING_STATISTIC: () => import('@/views/landing/statistic/view/Statistic.vue'),
  // 레몬머니레터
  LANDING_LETTER_LIST: () => import('@/views/landing/letter/view/LetterList.vue'),
  LANDING_LETTER_REGISTER: () => import('@/views/landing/letter/view/LetterRegister.vue'),
  LANDING_LETTER_MODIFY: () => import('@/views/landing/letter/view/LetterRegister.vue'),
  LANDING_LETTER_DETAIL: () => import('@/views/landing/letter/view/LetterDetail.vue'),
  // 블로그 - 이벤트
  LANDING_BLOG_NOTICE_LIST: () => import('@/views/landing/blog/notice/view/NoticeList.vue'),
  LANDING_BLOG_NOTICE_REGISTER: () => import('@/views/landing/blog/notice/view/NoticeRegister.vue'),
  LANDING_BLOG_NOTICE_MODIFY: () => import('@/views/landing/blog/notice/view/NoticeRegister.vue'),
  LANDING_BLOG_NOTICE_DETAIL: () => import('@/views/landing/blog/notice/view/NoticeDetail.vue'),
  // 블로그 - 포스트
  LANDING_BLOG_POST_LIST: () => import('@/views/landing/blog/post/view/PostList.vue'),
  LANDING_BLOG_POST_REGISTER: () => import('@/views/landing/blog/post/view/PostRegister.vue'),
  LANDING_BLOG_POST_MODIFY: () => import('@/views/landing/blog/post/view/PostRegister.vue'),
  LANDING_BLOG_POST_DETAIL: () => import('@/views/landing/blog/post/view/PostDetail.vue'),
  // 블로그 - 공지사항
  LANDING_BLOG_NEWS_LIST: () => import('@/views/landing/blog/news/view/NewsList.vue'),
  LANDING_BLOG_NEWS_REGISTER: () => import('@/views/landing/blog/news/view/NewsRegister.vue'),
  LANDING_BLOG_NEWS_MODIFY: () => import('@/views/landing/blog/news/view/NewsRegister.vue'),
  LANDING_BLOG_NEWS_DETAIL: () => import('@/views/landing/blog/news/view/NewsDetail.vue'),
  // 채용공고관리
  LANDING_RECRUIT_LIST: () => import('@/views/landing/recruit/view/RecruitList.vue'),
  LANDING_RECRUIT_REGISTER: () => import('@/views/landing/recruit/view/RecruitRegister.vue'),
  LANDING_RECRUIT_MODIFY: () => import('@/views/landing/recruit/view/RecruitRegister.vue'),
  LANDING_RECRUIT_DETAIL: () => import('@/views/landing/recruit/view/RecruitDetail.vue'),

  LANDING_CACHE_INVALIDATION: () => import('@/views/landing/cacheInvalidation/view/CacheInvalidation.vue'),

  // --- admin ---
  // 직원관리
  ADMIN_MEMBER_LIST: () => import('@/views/admin/member/view/MemberList.vue'),
  ADMIN_MEMBER_DETAIL: () => import('@/views/admin/member/view/MemberDetail.vue'),
  ADMIN_MEMBER_REGISTER: () => import('@/views/admin/member/view/MemberRegister.vue'),
  ADMIN_MEMBER_MODIFY: () => import('@/views/admin/member/view/MemberModify.vue'),
  // 시스템 로그
  ADMIN_SYSTEM_LIST: () => import('@/views/admin/system/view/SystemList.vue'),
  // 데이터 조회
  ADMIN_DB_LIST: () => import('@/views/admin/db/view/DbList.vue'),

  //  --- guide ---
  GUIDE_COMPONETS_FORMS: () => import('@/views/guide/components/forms/Forms.vue'),
  GUIDE_COMPONETS_TAB: () => import('@/views/guide/components/tab/Tab.vue'),
  GUIDE_COMPONETS_SEARCH: () => import('@/views/guide/components/search/Search.vue'),
  GUIDE_COMPONETS_BOARD: () => import('@/views/guide/components/board/Board.vue'),
  GUIDE_COMPONETS_TABLE: () => import('@/views/guide/components/table/Table.vue'),
  GUIDE_COMPONETS_CATEGORY: () => import('@/views/guide/components/category/Category.vue'),
  GUIDE_COMPONETS_CALENDAR: () => import('@/views/guide/components/calendar/Calendar.vue'),
  GUIDE_COMPONETS_BUTTONS: () => import('@/views/guide/components/buttons/Buttons.vue'),
  GUIDE_COMPONETS_BADGES: () => import('@/views/guide/components/badges/Badges.vue'),
  GUIDE_COMPONETS_WIDGETS: () => import('@/views/guide/components/widgets/Widgets.vue'),
  GUIDE_COMPONETS_STEP: () => import('@/views/guide/components/step/Step.vue'),
  GUIDE_ICONS: () => import('@/views/guide/icons/Icons.vue'),
  GUIDE_CHARTS: () => import('@/views/guide/charts/chart/Chart.vue'),
  GUIDE_LAYER: () => import('@/views/guide/layer/Layer.vue'),
}

export default routerComponent;