const appMenuData =
[
  {
    title: '점검관리',
    id: 'INSPECTION',
    menuIcon: '🛠',
    routerPath: 'APP_INSPECTION',
  },
  {
    title: '광고관리',
    id: 'ADVERTISEMENT',
    menuIcon: '📢',
    routerPath: 'APP_ADVERTISEMENT_LIST',
  },
  {
    title: '베너관리',
    id: 'BANNER',
    menuIcon: '🖼',
    menuList: [ 
      {
        title: '베너게시관리',
        id: 'POSTING',
        requiresMinRole: 'USER',
        routerPath: 'APP_BANNER_POSTING',
      },
      {
        title: '베너소스관리',
        id: 'SOURCE',
        requiresMinRole: 'USER',
        routerPath: 'APP_BANNER_SOURCE_LIST',
      },
    ]
  },
  {
    title: '포인트 벌기 관리',
    id: 'MOREPOINT',
    menuIcon: '🪙',
    routerPath: 'APP_MOREPOINT_LIST',
  },
  // {
  //   title: '업데이트관리',
  //   id: 'UPDATE',
  //   menuIcon: '♻️',
  //   routerPath: 'APP_UPDATE_LIST',
  //   disabled: true,
  // },
  {
    title: '알림관리',
    id: 'ALARM',
    menuIcon: '🔔',
    routerPath: 'APP_ALARM_LIST',
  },
  // {
  //   title: '배너관리',
  //   id: 'BANNER',
  //   menuIcon: '🖼',
  //   routerPath: 'APP_BANNER_LIST',
  //   disabled: true,
  // },
]

export { appMenuData }