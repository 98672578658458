const routerPath = {
  HOME: '/',
  LOGIN: '/login',
  MY: '/my',

  // 대시보드
  DASHBOARD_DASHBOARD: '/dashboard',
  // --- service ---
  // 문의관리
  SERVICE_INQUIRY_LIST: '/service/inquiry/list',
  SERVICE_INQUIRY_DETAIL: '/service/inquiry/detail/:id',
  // 회원관리
  SERVICE_USER_SEARCH_LIST: '/service/user/search/list',
  SERVICE_USER_PLUS_LIST: '/service/user/plus/list',
  SERVICE_USER_LEAVE_LIST: '/service/user/leave/list',
  SERVICE_USER_SEARCH_DETAIL: '/service/user/search/detail/:id',
  SERVICE_USER_PLUS_DETAIL: '/service/user/plus/detail/:id',
  SERVICE_USER_LEAVE_DETAIL: '/service/user/leave/detail/:id',
  // 멤버십 관리
  SERVICE_MEMBERSHIP_PAYMENTS_LIST: '/service/membership/payments',
  // 약관관리
  SERVICE_TERM_LIST: '/service/term/list',
  SERVICE_TERM_DETAIL: '/service/term/detail/:type/:id',
  SERVICE_TERM_REGISTER: '/service/term/register/:type',
  SERVICE_TERM_MODIFY: '/service/term/modify/:type/:id',
  // FAQ관리
  SERVICE_FAQ_LIST: '/service/faq/list',
  SERVICE_FAQ_REGISTER: '/service/faq/register',
  SERVICE_FAQ_MODIFY: '/service/faq/modify/:id',
  SERVICE_FAQ_TOPTEN: '/service/faq/topten',
  SERVICE_FAQ_CATEGORY: '/service/faq/category',
  // 프로모션관리
  SERVICE_PROMOTION_LIST: '/service/promotion/list',
  SERVICE_PROMOTION_REGISTER: '/service/promotion/register',
  SERVICE_PROMOTION_MODIFY: '/service/promotion/modify/:status/:id',

  SERVICE_REDEEM_LIST: '/service/redeem/list',
  SERVICE_REDEEM_REGISTER: '/service/redeem/register',

  // --- app ---
  // 점검관리
  APP_INSPECTION: '/app/inspection',
  // 광고관리
  APP_ADVERTISEMENT_LIST: '/app/advertisement',
  // 업데이트관리
  APP_UPDATE_LIST: '/app/update/list',
  APP_UPDATE_DETAIL: '/app/update/detail/:id',
  APP_UPDATE_REGISTER: '/app/update/register',
  APP_UPDATE_MODIFY: '/app/update/modify/:id',
  // 알림
  APP_ALARM_LIST: '/app/alarm/list',
  APP_ALARM_DETAIL: '/app/alarm/detail/:status/:id',
  APP_ALARM_REGISTER: '/app/alarm/register',
  APP_ALARM_MODIFY: '/app/alarm/modify/:status/:id',
  // 베너관리
  APP_BANNER_POSTING: '/app/banner/posting',
  APP_BANNER_SOURCE_LIST: '/app/banner/source/list',
  APP_BANNER_SOURCE_REGISTER: '/app/banner/source/register',
  APP_BANNER_SOURCE_MODIFY: '/app/banner/source/modify/:id',
  // 포인트벌기관리
  APP_MOREPOINT_LIST: '/app/more-point',

  // --- landing ---
  // 출시알림 이벤트 통계
  LANDING_STATISTIC: '/landing/statistic',
  // 레몬머니레터
  LANDING_LETTER_LIST: '/landing/letter/list',
  LANDING_LETTER_DETAIL: '/landing/letter/detail/:id',
  LANDING_LETTER_REGISTER: '/landing/letter/register',
  LANDING_LETTER_MODIFY: '/landing/letter/modify/:id',
  // 이벤트
  LANDING_BLOG_NOTICE_LIST: '/landing/notice/list',
  LANDING_BLOG_NOTICE_DETAIL: '/landing/notice/detail/:id',
  LANDING_BLOG_NOTICE_REGISTER: '/landing/notice/register',
  LANDING_BLOG_NOTICE_MODIFY: '/landing/notice/modify/:id',
  // 포스트
  LANDING_BLOG_POST_LIST: '/landing/post/list',
  LANDING_BLOG_POST_DETAIL: '/landing/post/detail/:link',
  LANDING_BLOG_POST_REGISTER: '/landing/post/register',
  LANDING_BLOG_POST_MODIFY: '/landing/post/modify/:link',
  // 공지사항
  LANDING_BLOG_NEWS_LIST: '/landing/news/list',
  LANDING_BLOG_NEWS_DETAIL: '/landing/news/detail/:id',
  LANDING_BLOG_NEWS_REGISTER: '/landing/news/register',
  LANDING_BLOG_NEWS_MODIFY: '/landing/news/modify/:id',
  // 채용공고관리
  LANDING_RECRUIT_LIST: '/landing/recruit/list',
  LANDING_RECRUIT_DETAIL: '/landing/recruit/detail/:id',
  LANDING_RECRUIT_REGISTER: '/landing/recruit/register',
  LANDING_RECRUIT_MODIFY: '/landing/recruit/modify/:id',

  LANDING_CACHE_INVALIDATION: '/landing/cache-invalidation',

  // --- admin ---
  // 직원관리
  ADMIN_MEMBER_LIST: '/admin/member/list',
  ADMIN_MEMBER_DETAIL: '/admin/member/detail/:id',
  ADMIN_MEMBER_REGISTER: '/admin/member/register',
  ADMIN_MEMBER_MODIFY: '/admin/member/modify/:id',
  // 시스템 로그
  ADMIN_SYSTEM_LIST: '/admin/system/list',
  // 데이터 조회
  ADMIN_DB_LIST : '/admin/db/list',

  //  --- guide ---
  GUIDE_COMPONETS_FORMS: '/guide/components/forms',
  GUIDE_COMPONETS_TAB: '/guide/components/tab',
  GUIDE_COMPONETS_SEARCH: '/guide/components/search',
  GUIDE_COMPONETS_BOARD: '/guide/components/board',
  GUIDE_COMPONETS_TABLE: '/guide/components/table',
  GUIDE_COMPONETS_CATEGORY: '/guide/components/category',
  GUIDE_COMPONETS_CALENDAR: '/guide/components/calendar',
  GUIDE_COMPONETS_BUTTONS: '/guide/components/buttons',
  GUIDE_COMPONETS_BADGES: '/guide/components/badges',
  GUIDE_COMPONETS_WIDGETS: '/guide/components/widgets',
  GUIDE_COMPONETS_STEP: '/guide/components/step',
  GUIDE_ICONS: '/guide/icons',
  GUIDE_CHARTS: '/guide/charts',
  GUIDE_LAYER: '/guide/layer',
};

export default routerPath;