import { serviceMenuData } from '@/constants/serviceMenuData';
import { appMenuData } from '@/constants/appMenuData';
import { landingMenuData } from '@/constants/landingMenuData';
import { adminMenuData } from '@/constants/adminMenuData';
import { guideMenuData } from '@/constants/guideMenuData';

const menuData =
[
    // {
    //   title: '대시보드',
    //   id: 'DASHBOARD',
    //   menuIcon: '🚥',
    //   routerPath: 'DASHBOARD_DASHBOARD',
    // },
    { 
      title: '서비스 관리',
      foldTitle: '서비스',
      id: 'SERVICE',
      menuList: serviceMenuData,
      isFold: false,
    },
    { 
      title: '앱 관리',
      foldTitle: '앱',
      id: 'APP',
      menuList: appMenuData,
      isFold: false,
    },
    { 
      title: '랜딩 페이지 관리',
      foldTitle: '랜딩',
      id: 'LANDING',
      menuList: landingMenuData,
      isFold: false,
    },
    { 
      title: '어드민 관리',
      foldTitle: '어드민',
      id: 'ADMIN',
      menuList: adminMenuData,
      requiresMinRole: 'ADMIN',
      isFold: false,
    },
    { 
      title: 'Guide',
      foldTitle: 'Guide',
      id: 'GUIDE',
      menuList: guideMenuData,
      isFold: true,
      isOnlyDev: true,
    },
]

export { menuData }