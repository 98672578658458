const commonData = {
  break_point: 1024,
  service_name:'firfin', 
  admin_name:'service admin',
  isSnbRoleBadge: true,
  name_letter_spacing : {
    header:{
      service: '0.06em',
      admin: '0.03em',
    },
    login:{
      service: '0em',
      admin: '0.1em',
    }
  }
}

export { commonData }